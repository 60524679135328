/* eslint-disable func-names, prefer-arrow-callback */
import { string } from 'yup';
import {
  addDays,
  addSeconds,
  isBefore,
  format,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';

export const cfpsid = string()
  .required('Please provide a valid Confirmation Code.')
  .max(16, 'Please provide a valid Confirmation Code.')
  .min(16, 'Please provide a valid Confirmation Code.');

export const cancelReason = string()
  .required('Sorry, there was a problem processing your information. A cancel reason must be selected.');

export const selectedOptionId = string()
  .required('Please select an extension period.');

// specific phoneNumber rules for offline COAs
// not currently used but will potentially
// be used in the future
export const phoneNumber = string()
  .label('Phone Number')
  .transform((value) => (!value ? '' : value))
  // min value test with offline COA tests
  .test(
    'min',
    'Please enter a phone number.',
    function (value) {
      const {
        initialPhoneNumber,
      } = this.options.context;

      if (initialPhoneNumber && value.length < 10) {
        return false;
      }

      return true;
    },
  )
  .max(10, 'Please enter a phone number.')
  .test(
    'validPhoneNumber',
    'Please enter a valid phone number.',
    function (value) {
      if (!value) return true;
      const isValidPhone = /^\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/gi.test(value);
      const isInvalidPrefix = /^\(?(.{4}11).*$/gi.test(value);
      const isInvalid555Phone = /^.*(555)[-. ]?((01[0-9]{2})|(1212))$/gi.test(value);
      return isValidPhone && !isInvalidPrefix && !isInvalid555Phone;
    },
  );

export const oldPoBoxNumber = string()
  .label('PO Box Number')
  .nullable()
  .test(
    'R777Exception',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.oldAddressError === 'R777AddressException'
        && this.options.context?.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'OldPoBoxRequiredException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.oldAddressError === 'OldPoBoxRequiredException'
        && this.options.context?.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryPoBoxException',
    'According to our records, this is an invalid box number. Please review and update the address before continuing, then click "Next."',
    function () {
      if (this.options.context?.oldAddressError === 'InvalidPrimaryPoBoxException'
        && this.options.context?.showOldPoBox) return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (!val) return true;
      return val.match(/^po box [a-zA-Z0-9]{1,10}$/i);
    },
  );

export const oldStreetAddress = string()
  .test(
    'Old Street Address InvalidSecondaryInformationException',
    'Invalid Address. Enter unit or apartment number in the street name field.',
    function () {
      if (this.options?.context?.oldAddressError === 'InvalidSecondaryInformationException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'InvalidChangeException',
    'The primary number cannot be modified for your old address. If you need to make this change, you will need to cancel this change of address order and submit a new one.',
    function () {
      if (this.options?.context?.oldAddressError === 'InvalidChangeException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'UnneededSecondaryInformationException',
    'Our records indicate a unit or apartment number is not needed for this address.  Remove the unit or apartment number or enter a valid address.',
    function () {
      if (this.options?.context?.oldAddressError === 'UnneededSecondaryInformationException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'TooManyComponentsException',
    'The "Street Address" field should not include the city, state, or ZIP. Remove the city, state or ZIP from this field.',
    function () {
      if (this.options.context?.oldAddressError === 'TooManyComponentsException') return false;
      return true;
    },
  )
  .test(
    'UndeliverableAddressException',
    'Address not valid, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'UndeliverableAddressException') return false;
      return true;
    },
  )
  .test(
    'AmsMultipleAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'AmsMultipleAddressException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.oldAddressError === 'InvalidPrimaryInformationException') return false;
      return true;
    },
  )
  .test(
    'OldAndNewAddressMatchException',
    'The old and new address match.  Enter a different old or new address.',
    function () {
      if (this.options.context?.oldAddressError === 'OldAndNewAddressMatchException') return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (val?.toLowerCase().indexOf('po box') !== -1 && !val?.match(/^po box [a-zA-Z0-9]{1,10}$/i)) return false;
      return true;
    },
  )
  .required('This field is required.');


export const newPoBoxNumber = string()
  .label('PO Box Number')
  .nullable()
  .test(
    'NewPoBoxRequiredException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.newAddressError === 'NewPoBoxRequiredException') return false;
      return true;
    },
  )
  .test(
    'R777AddressException',
    'A PO Box is required to proceed.',
    function () {
      if (this.options.context?.newAddressError === 'R777AddressException') return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (!val) return true;
      return val.match(/^po box [a-zA-Z0-9]{1,10}$/i);
    },
  );

export const newPrivateMailbox = string()
  .label('Private Mailbox')
  .nullable()
  .test(
    'allowed characters',
    'We allow the following characters: PMB, space, -, # or numbers. Please try again using the following format: PMB number or #number.',
    function (val) {
      if (!val) return true;
      if (val.match(/(#)(?:\d+)/)) return true;

      const v = val.toLowerCase().split(' ');
      return v.length === 2 && (v[0] === 'pmb') && v[1].length && v[1].match(/^[a-zA-Z0-9 #-]*$/);
    },
  )
  .test(
    'CCR1CmraAddressException Exceptions',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options?.context?.newAddressError === 'CCR1CmraAddressException' && this.options?.context?.showNewPrivateMailbox) {
        return false;
      }
      return true;
    },
  );

export const newStreetAddress = string()
  .test(
    'CmraAddressException',
    'To forward mail to a commercial mail receiving agency, you must include the private mailbox number. Please enter the private mailbox number in the "Private Mailbox" field, then click "Save Changes." USPS will standardize your address upon submission.',
    function () {
      if (this.options?.context?.newAddressError === 'CmraAddressException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'C777AddressException',
    'Invalid address.  Enter PO Box number for this address.',
    function () {
      if (this.options.context?.newAddressError === 'C777AddressException') return false;
      return true;
    },
  )
  .test(
    'UnneededSecondaryInformationException',
    'Our records indicate a unit or apartment number is not needed for this address.  Remove the unit or apartment number or enter a valid address.',
    function () {
      if (this.options?.context?.newAddressError === 'UnneededSecondaryInformationException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'TooManyComponentsException',
    'The "Street Address" field should not include the city, state, or ZIP. Remove the city, state or ZIP from this field.',
    function () {
      if (this.options.context?.newAddressError === 'TooManyComponentsException') return false;
      return true;
    },
  )
  .test(
    'UndeliverableAddressException',
    'Address not valid, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'UndeliverableAddressException') return false;
      return true;
    },
  )
  .test(
    'CCR1CmraAddressException Exceptions',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options?.context?.newAddressError === 'CCR1CmraAddressException' && !this.options?.context?.showNewPrivateMailbox) {
        return false;
      }
      return true;
    },
  )
  .test(
    'PrivateMailboxRequiredException',
    'A private mailbox number is required for a commercial mail receiving agency.  Enter the private mailbox number in the "Private Mailbox" field.',
    function () {
      if (this.options?.context?.newAddressError === 'PrivateMailboxRequiredException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'New Street Address AMS21Zip5',
    'We cannot locate the entered street number in our records. Please correct the street number of the "street address" or if you receive PO Box Delivery, input your PO Box number in the PO Box field and click "Save Changes" to continue.',
    function () {
      if (this.options?.context?.newAddressError === 'AMS21Zip5') {
        return false;
      }
      return true;
    },
  )
  .test(
    'New Street Address InvalidAddressChangeException',
    'We cannot find the street address that you entered in our records. Please check the spelling of all address information to ensure it is accurate. If you are sure the existing address is correct, please contact your local Post Office to validate your mailing address.',
    function () {
      if (this.options?.context?.newAddressError === 'InvalidAddressChangeException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'New Street Address InvalidSecondaryInformationException',
    'Invalid Address. Enter unit or apartment number in the street name field.',
    function () {
      if (this.options?.context?.newAddressError === 'InvalidSecondaryInformationException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'InvalidPrimaryPoBoxException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.newAddressError === 'InvalidPrimaryPoBoxException') return false;
      return true;
    },
  )
  .test(
    'New Street Address InvalidChangeException',
    'The primary number cannot be modified for your old address. If you need to make this change, you will need to cancel this Change-of-Address order and submit a new one.',
    function () {
      if (this.options?.context?.newAddressError === 'InvalidChangeException') {
        return false;
      }
      return true;
    },
  )
  .test(
    'PrimaryNumberMissingException',
    'Invalid address.  Enter a street number.',
    function () {
      if (this.options.context?.newAddressError === 'PrimaryNumberMissingException') return false;
      return true;
    },
  )
  .test(
    'InvalidPrimaryInformationException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'InvalidPrimaryInformationException') return false;
      return true;
    },
  )
  .test(
    'AmsMultipleAddressException',
    'Address not found, enter a valid address.',
    function () {
      if (this.options.context?.newAddressError === 'AmsMultipleAddressException') return false;
      return true;
    },
  )
  .test(
    'OldAndNewAddressMatchException',
    'The old and new address match.  Enter a different old or new address.',
    function () {
      if (this.options.context?.newAddressError === 'OldAndNewAddressMatchException') return false;
      return true;
    },
  )
  .test(
    'NewPoBoxRequiredException',
    'Invalid PO Box number. Enter valid PO box number.',
    function () {
      if (this.options.context?.newAddressError === 'NewPoBoxRequiredException'
          && !this.options.context?.showNewPoBox) return false;
      return true;
    },
  )
  .test(
    'format',
    'It looks like you are trying to enter a PO BOX address. Please format it as follows: PO BOX 123 using a maximum of 10 characters.',
    function (val) {
      if (val?.toLowerCase().indexOf('po box') !== -1 && !val?.match(/^po box [a-zA-Z0-9]{1,10}$/i)) return false;
      return true;
    },
  )
  .required('We require new address');

export const startDate = string()
  .transform(function (_, originalValue) {
    return originalValue ? originalValue.replace(/-/g, '') : '';
  })
  .label('Start Forwarding Date')
  .test(
    'min',
    'The Date to Begin Forwarding Mail cannot be more than 30 days earlier than today.',
    function (value) {
      const today = startOfDay(new Date());

      return value >= format(subDays(today, 30), 'yyyyMMdd');
    },
  )
  .test(
    'minOtherBilling',
    'According to our records, you used a billing address other than your new or old address to validate your identity, the forwarding date must be no sooner than 7 days from the date your COA order was submitted.',
    function (value) {
      const parsedCoaSubmitDate = parseISO(this.options?.context?.coaSubmitDate) || null;

      if (
        this.options?.context?.billingAddressType === 'OTHER'
        && parsedCoaSubmitDate
      ) {
        return value >= format(addDays(parsedCoaSubmitDate, 7), 'yyyyMMdd');
      }

      return true;
    },
  )
  .test(
    'minOtherBillingBiz',
    'According to our records, you used a billing address other than your new or old address to validate your identity, the forwarding date must be no sooner than 7 days from the date your COA order was submitted.',
    function (value) {
      const parsedCoaSubmitDate = parseISO(this.options?.context?.coaSubmitDate) || null;

      if (
        this.options?.context?.billingAddressType === 'OTHER'
        && this.options?.context?.moverType === 'BUSINESS'
        && parsedCoaSubmitDate
      ) {
        return value >= format(addDays(parsedCoaSubmitDate, 7), 'yyyyMMdd');
      }

      return true;
    },
  )
  .test(
    'max',
    'The Date to Begin Forwarding Mail cannot be more than 90 days in the future.',
    function (value) {
      const today = startOfDay(new Date());

      return value <= format(addDays(today, 90), 'yyyyMMdd');
    },
  )
  .test(
    'InvalidChangeException',
    'According to our records, you used a billing address other than your new or old address to validate your identity, the forwarding date must be no sooner than 7 days from the date your COA order was submitted.',
    function () {
      if (this.options?.context?.startDateError === 'InvalidChangeException') {
        return false;
      }
      return true;
    },
  )
  .required('The Date to Begin Forwarding Mail should not be blank.');

export const endDate = string()
  .transform(function (_, originalValue) {
    return originalValue ? originalValue.replace(/-/g, '') : '';
  })
  .label('End Forwarding Date')
  .test(
    'min',
    'The Date to Stop Forwarding Mail cannot be less than 7 days later than today.',
    function (value) {
      const today = startOfDay(new Date());

      return value >= format(addDays(today, 7), 'yyyyMMdd');
    },
  )
  .test(
    'min 15 day forwarding period',
    'The Date to Stop Forwarding Mail cannot be less than 15 days after the Date to Begin Forwarding Mail.',
    function (value) {
      const today = startOfDay(new Date());
      const parsedStartDate = this.options.context.startDate
        && parseISO(this.options.context.startDate);
      const fifteenDaysAfterStartDate = format(addDays(parsedStartDate || today, 15), 'yyyyMMdd');

      return value >= fifteenDaysAfterStartDate;
    },
  )
  .test(
    'max date',
    'Temporary Mail Forwarding cannot exceed 185 days in duration.',
    function (value) {
      const today = startOfDay(new Date());
      const parsedValue = parseISO(value);
      const parsedStartDate = this.options.context.startDate
        && parseISO(this.options.context.startDate);
      const sixMonthsAfterStartDate = addSeconds(addDays(parsedStartDate || today, 185), 1);

      return isBefore(parsedValue, sixMonthsAfterStartDate);
    },
  )
  .required('The Date to Stop Forwarding Mail should be entered for a temporary move.');
